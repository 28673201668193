<template>
    <v-col>     
        <v-spacer />
        <v-alert border="top" color="red px-5">{{ msg }}</v-alert>
        <v-btn block class="primary justify-space-around" :to="{ name: 'home' }">OK</v-btn>        
        <v-spacer />
    </v-col>
</template>

<script>
    export default {
        name: 'Error',        
        computed: {
            msg() {                
                if (this.$route.params.message) {
                    return this.$route.params.message;
                }
                
                if (this.$route.params.msg) {
                    return this.$route.params.msg;
                }

                return this.$route.query.message;
            }
        },
    }
</script>